import React from 'react';
import style from './RecommendedOfferLabel.module.css';
// FF
import { EX4796 } from 'config/flags';
const RecommendedOfferLabel = ({ recommended }) => {
    return (
        <div className={style['container-offer-label']} >
            {recommended?.offer_label && !!recommended?.offer_label ? (
                <>
                    <div
                        className={style['box-offer-label']}
                        style={{
                            color: !!recommended?.offer_label ? '#fff' : 'transparent',
                            visibility: !!recommended?.offer_label ? 'visible' : 'hidden',
                            
                        }}
                    >
                        <span className={style['text-offer-label']}>{recommended?.offer_label}</span>
                    </div>
                    {!EX4796 && <div className={style['box-empty']}></div>}
                </>
            ) : (
                !EX4796 && (
                    <div className={style['empty-offer-label']}>
                        <span></span>
                        <br />
                    </div>
                )
            )}
        </div>
    );
};

export default RecommendedOfferLabel;
