// components
import { CarouselComponent } from '@commons/components/CarouselComponent';
// interfaces
import { CarouselModuleProps } from './CarouselModule.interface';

const CarouselModule = ({
    content,
    headerText,
    EmptyComponent,
    ItemComponent,
    fetching,
    showPopoverPortalDiv,
    RecommendedCardNotResults,
    text,
    subText,
    recommendedNotResults,
}: CarouselModuleProps): JSX.Element => {
    return (
        <CarouselComponent
            content={content}
            headerText={headerText}
            EmptyComponent={EmptyComponent}
            ItemComponent={ItemComponent}
            fetching={fetching}
            showPopoverPortalDiv={showPopoverPortalDiv}
            RecommendedCardNotResults={RecommendedCardNotResults}
            text={text}
            subText={subText}
            recommendedNotResults={recommendedNotResults}
        />
    );
};

export default CarouselModule;
