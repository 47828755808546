import { FC } from 'react';
// interfaces
import { ExCarouselDotsProps } from './ExCarouselDots.interface';
// styles
import styles from './ExCarouselDots.module.css';

const ExCarouselDots: FC<ExCarouselDotsProps> = ({ totalPages, currentPage, onPageChange }) => {
    return (
        <div className={styles['excarousel-dots']}>
            {Array.from({ length: totalPages }).map((_, index) => {
                return (
                    <span
                        key={index+1}
                        className={`${styles['dot']} ${index === currentPage ? styles['active'] : ''}`}
                        onClick={() => onPageChange(index)}
                    />
                );
            })}
        </div>
    );
};

export default ExCarouselDots;
