import { lazy, Suspense } from 'react';
// COMPONENTS
import { RecommendedCardBodyDescription } from './Description/RecommendedCardBodyDescription';
import { RecommendedCardPrice } from './Price/RecommendedCardPrice';
import { RecommendedOfferLabel } from '../RecommendedOfferLabel';
import { Placeholder } from 'rsuite';
export const LazyRecommendedCardBodyImg = lazy(() =>
    import('./Photo/RecommendedCardBodyImg').then(module => ({ default: module.RecommendedCardBodyImg }))
);

export const RecommendedCardBody = ({ recommended, idx, popoverPortalDiv, visibleIds }) => {
    return (
        <>
            <Suspense
                fallback={
                    <Placeholder.Paragraph
                        graph="image"
                        active
                        style={{
                            height: 130,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        rows={0}
                    />
                }
            >
                <LazyRecommendedCardBodyImg recommended={recommended} />
            </Suspense>
            <RecommendedOfferLabel recommended={recommended} />
            <RecommendedCardBodyDescription
                recommended={recommended}
                popoverPortalDiv={popoverPortalDiv}
                visibleIds={visibleIds}
            />
            <RecommendedCardPrice recommended={recommended} idx={idx} />
        </>
    );
};
