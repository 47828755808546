import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// contexts
import {
    NewUseCart,
    useListBoxDrugManufacturersContext,
    useNewSearchProduct,
    useProgressBarPriceContext,
    useRecommendedProducts,
} from 'context';

const useHomeModule = () => {
    const { resetStates } = useProgressBarPriceContext();
    const { setIdsDeleted } = useListBoxDrugManufacturersContext();
    const { visibility, openModalDelete, emptyCart, setOpenModalDelete } = NewUseCart();
    const { isOpen } = useNewSearchProduct();
    const { recommendedProducts } = useRecommendedProducts();
    const { query } = useRouter();
    const [firstCarouselItems, setFirstCarouselItems] = useState([]);
    const [secondCarouselItems, setSecondCarouselItems] = useState([]);

    useEffect(() => {
        resetStates();
    }, []);

    useEffect(() => {
        const splitRecommendedProducts = () => {
            if (typeof window !== 'undefined') {
                // Check if window is defined
                const windowWidth = window.innerWidth;
                let firstItems = [];
                let secondItems = [];

                if (recommendedProducts && recommendedProducts.length > 0) {
                    if (windowWidth >= 1440) {
                        firstItems = recommendedProducts.slice(0, 6);
                        secondItems = recommendedProducts.slice(6);
                    } else {
                        firstItems = recommendedProducts.slice(0, 4);
                        secondItems = recommendedProducts.slice(4);
                    }
                }

                setFirstCarouselItems(firstItems);
                setSecondCarouselItems(secondItems);
            }
        };

        splitRecommendedProducts();

        // Optional: Add an event listener for window resize to recalculate the split
        window.addEventListener('resize', splitRecommendedProducts);
        return () => {
            window.removeEventListener('resize', splitRecommendedProducts);
        };
    }, [recommendedProducts]);

    return {
        openModalDelete,
        setOpenModalDelete,
        emptyCart,
        query,
        isOpen,
        firstCarouselItems,
        secondCarouselItems,
        visibility,
        setIdsDeleted,
    };
};

export default useHomeModule;
