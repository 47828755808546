import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// contexts
import { NewUseCart } from 'context';
import { useDragAndDrop } from 'context/dragAndDropContext';
import { useStatusContext } from 'context/control-state';
// handlers
import { handleChangeMomentary, handleOperation, handlerAddProductCart } from '../handlers';
import { formatObject } from '../handlers/formatObject';

const useRecommendedItem = (props) => {
    // hook
    const { posId } = useRouter()?.query;
    // context cart
    const { addProduct, existsProductInCart, getProductByBarcode, updateProduct, visibility, toggle } = NewUseCart();
    // context drag and drop
    const { visibleDragDrop } = useDragAndDrop();
    // props
    const { popoverPortalDiv, idx, ...recommended } = props;

    const cartId = getProductByBarcode(recommended?.product?.ean)?.id;
    const quantity = getProductByBarcode(recommended?.product?.ean)?.quantity;
    const isProductInCart = existsProductInCart(recommended?.product?.ean);

    // STATE LOCAL INPUT
    const [value, setValue] = useState<number>(quantity);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const { setDisplayModalOnBoarding, displayModalOnBoarding } = useStatusContext();

    // LISTEN UPDATE QUANTITY & SETTER STATE
    useEffect(() => {
        if (isFetching || quantity !== value) setValue(quantity);
        setIsFetching(false);
    }, [quantity]);

    // SUBS
    const handleMinus = (v) => {
        setIsFetching(true);
        return handleOperation(formatObject(recommended, cartId), v, updateProduct);
    };

    // ADD
    const handlePlus = (v) => {
        if (displayModalOnBoarding) {
            setDisplayModalOnBoarding(false);
        }
        setIsFetching(true);
        return handleOperation(formatObject(recommended, cartId), v, updateProduct);
    };

    // WRITING MOMENTARY
    const handleWriting = (v) => {
        return handleChangeMomentary(v, setValue);
    };

    // INPUT ONBLUR
    const handleInput = (v) => {
        if (quantity !== +v) {
            setIsFetching(true);
            return handleOperation(formatObject(recommended, cartId), +v, updateProduct);
        }
    };

    // ADD PRODUCT IN CART
    const handlerAddProduct = (item: any) => {
        handlerAddProductCart(item, addProduct);
        if (!visibility) toggle(posId);
    };

    return {
        recommended,
        visibleDragDrop,
        isProductInCart,
        handlePlus,
        handleMinus,
        handleWriting,
        handleInput,
        idx,
        popoverPortalDiv,
        isFetching,
        setIsFetching,
        value,
        handlerAddProduct,
    };
};

export default useRecommendedItem;
