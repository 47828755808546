// objetc used when update quantity
export const formatObject = (object, cartId) => {
    return {
        id: cartId,
        barcode: object?.product?.ean,
        description: `${object?.product?.name} ${object?.product?.presentation}`,
    };
};

// object used when create product in cart
export const formatObjectCreate = (item: any) => {
    const description = !!item?.product?.presentation
        ? `${item?.product?.name} ${item?.product?.presentation}`
        : `${item?.product?.name}`;
    return {
        _source: {
            ean: [item.product.ean],
            description: `${description}`,
            is_recommended: true,
            suggested_quantity: item?.suggested_quantity && !!item?.suggested_quantity ? item.suggested_quantity : null,
            is_included: 2,
        },
    };
};
