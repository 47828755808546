import Image from 'next/image';
// styles
import styles from './RecommendedCardNotResults.module.css';

const RecommendedCardNotResults = ({ text, subText }) => {
    return (
        <div className={styles['container']}>
            <Image
                src={'/assets/images/empty_recommendations.svg'}
                alt="carrousel empty image"
                width={139}
                height={175}
                draggable={false}
                priority
            />
            <p className={styles['text']}>
                {text}
                <span className={styles['subtext']}>{subText}</span>
            </p>
        </div>
    );
};

export default RecommendedCardNotResults;
