import React, { FC } from 'react';
// interfaces
import { ExCarouselHeaderProps } from './ExCarouselHeader.interface';
// components
import { ExCarouselDots } from './ExCarouselDots';
// styles
import styles from './ExCarouselHeader.module.css';
import { Placeholder } from 'rsuite';

const ExCarouselHeader: FC<ExCarouselHeaderProps> = ({
    totalPages,
    currentPage,
    handleDotClick,
    headerText,
    loading,
}): JSX.Element => {
    return (
        <div className={styles['container']}>
            {loading ? (
                <Placeholder.Paragraph
                    active
                    rows={1}
                    rowHeight={20}
                    style={{
                        height: 20,
                        width: 450,
                    }}
                />
            ) : (
                !!headerText && <p className={styles['title-section']}>{headerText}</p>
            )}
            {!loading && totalPages > 1 && (
                <ExCarouselDots totalPages={totalPages} currentPage={currentPage} onPageChange={handleDotClick} />
            )}
        </div>
    );
};

export default ExCarouselHeader;
