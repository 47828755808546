// components
import { RecommendedCardBody } from './RecommendedCardBody';
import { RecommendedCardFooter } from './RecommendedCardFooter';
// hooks
import { useRecommendedItem } from './hooks';
// styles
import styles from './RecommendedItem.module.css';

const RecommendedItem = ({ ...props }): JSX.Element => {
    // hook with logic
    const {
        recommended,
        visibleDragDrop,
        isProductInCart,
        handlePlus,
        handleMinus,
        handleWriting,
        handleInput,
        idx,
        popoverPortalDiv,
        isFetching,
        setIsFetching,
        value,
        handlerAddProduct,
    } = useRecommendedItem(props);

    return (
        <div className={styles['container']}>
            <div className={styles['box']}>
                <RecommendedCardBody
                    idx={idx + 1}
                    recommended={recommended}
                    popoverPortalDiv={popoverPortalDiv}
                    visibleIds={props?.visibleIds}
                />
                <RecommendedCardFooter
                    existsProductInCart={isProductInCart}
                    isFetching={isFetching}
                    recommended={recommended}
                    value={value}
                    handleWriting={handleWriting}
                    handleInput={handleInput}
                    handleMinus={handleMinus}
                    handlePlus={handlePlus}
                    disabledButtons={visibleDragDrop}
                    setIsFetching={setIsFetching}
                    handlerAddProductCart={handlerAddProduct}
                />
            </div>
        </div>
    );
};

export default RecommendedItem;
