import { useState, useRef, useEffect, Children } from 'react';

const useExCarousel = (children: any, loading: boolean) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [idxPagination, setIdxPagination] = useState(0);
    const totalItems = Children.count(children);
    const [visibleItems, setVisibleItems] = useState(1); // Inicialmente se asume al menos 1 elemento visible
    const containerRef = useRef<HTMLDivElement>(null);

    // Función para calcular la cantidad de elementos visibles
    const calculateVisibleItems = () => {
        const containerWidth = containerRef.current?.offsetWidth || 0;
        const itemWidth = 190 + 16; // 190px ancho del item + 16px de gap
        const newVisibleItems = Math.floor(containerWidth / itemWidth);
        setVisibleItems(Math.max(newVisibleItems, 1)); // Asegurar al menos 1 elemento visible
        setCurrentIndex(0); // Reiniciar el índice al principio cuando cambian los elementos visibles
        setIdxPagination(0); // idx pagination dots
    };

    useEffect(() => {
        // Calcular elementos visibles cuando el componente se monta
        calculateVisibleItems();

        // Crear un ResizeObserver para observar cambios en el tamaño del contenedor
        const resizeObserver = new ResizeObserver(calculateVisibleItems);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Agregar un eventListener para el evento resize del navegador
        window.addEventListener('resize', calculateVisibleItems);

        return () => {
            // Limpiar el ResizeObserver y el eventListener
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
            window.removeEventListener('resize', calculateVisibleItems);
        };
    }, []);

    const goToNextSlide = () => {
        const remainingItems = totalItems - currentIndex - visibleItems;
        if (remainingItems > 0) {
            const nextIndex = currentIndex + Math.min(visibleItems, remainingItems);
            const idxPagination = currentIndex + visibleItems;
            setIdxPagination(idxPagination);
            setCurrentIndex(nextIndex);
        } else {
            setIdxPagination(0);
            setCurrentIndex(0); // Ciclo infinito, vuelve al inicio
        }
    };

    const goToPrevSlide = () => {
        if (currentIndex > 0) {
            const prevIndex = currentIndex - visibleItems;
            setIdxPagination(prevIndex);
            setCurrentIndex(Math.max(prevIndex, 0));
        } else {
            setCurrentIndex(totalItems - visibleItems); // Vuelve al final
        }
    };

    const translateX: number = -(currentIndex * (100 / visibleItems));

    // Calcular el número total de páginas
    const totalPages: number = Math.ceil(totalItems / visibleItems);

    // Calcular la página actual
    // const currentPage: number = Math.floor(idxPagination / visibleItems);
    const currentPage: number = Math.ceil(idxPagination / visibleItems);

    const showPrevButton = (): boolean => {
        return !loading && currentIndex !== 0 && totalPages !== 1;
    };

    const showNextButton = (): boolean => {
        return !loading && totalPages !== 1;
    };

    // Función para obtener los id's de los elementos visibles
    const getVisibleIds = () => {
        if (Array.isArray(children)) {
            return children
                ?.slice(currentIndex, currentIndex + visibleItems)
                ?.map((child: any) => child?.props?.id);
        }
    };

    const handleDotClick = (idx: number) => {
        const maxIndex = totalItems - visibleItems;
        const newCurrentIndex = Math.min(idx * visibleItems, maxIndex);
        setCurrentIndex(Math.max(newCurrentIndex, 0)); // Asegurar que el índice no sea negativo
        setIdxPagination(Math.max(newCurrentIndex, 0));
    };

    return {
        containerRef,
        visibleItems,
        translateX,
        currentPage,
        totalPages,
        showPrevButton,
        showNextButton,
        goToNextSlide,
        goToPrevSlide,
        getVisibleIds,
        handleDotClick,
    };
};

export default useExCarousel;
