import { useEffect, useState } from 'react';
import { t } from 'i18next';
// configs
import { EX6914, EX6999 } from 'config/flags';
// components
import { Cart } from '../Cart';
import { CarouselModule } from '../CarouselModule';
import { RecommendedCardEmpty } from '@commons/components/RecommendedItem/RecommendedCardEmpty';
import { RecommendedItem } from '@commons/components/RecommendedItem';
import { DrugManufacturerWidget } from '../DrugManufacturersWidget';
import { DragAndDropUploaderModule } from '../DragAndDropUploaderModule';
import { RecommendedCardNotResults } from '@commons/components/RecommendedItem/RecommendedCardNotResults';
// contexts
import { ModalConfirmation, RsuiteTooltip } from '@commons/components';
import {
    DrugManufacturerWidgetProvider,
    useLayoutFooterContext,
    usePointOfSale,
    useRecommendedProducts,
} from 'context';
import { useStatusContext } from 'context/control-state';
// hooks
import { useHomeModule } from './hooks';
import { useUser } from 'utils/hooks';
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';
// styles
import styles from './HomeModule.module.css';

const HomeModule = (): JSX.Element => {
    const { pointOfSale } = usePointOfSale();
    // zetti
    const isZetti = isZettiFormat(pointOfSale);
    const isGestorERP = isGestorERPFormat(pointOfSale);

    const validateERP = !isZetti && !isGestorERP;

    const {
        openModalDelete,
        setOpenModalDelete,
        emptyCart,
        query,
        isOpen,
        firstCarouselItems,
        secondCarouselItems,
        visibility,
        setIdsDeleted,
    } = useHomeModule();

    const { footerExpanded, showFooterContext } = useLayoutFooterContext();

    const { counterStep } = useStatusContext();

    const [width, setWidth] = useState(0);
    const { user } = useUser({});
    const alerts: any = Object.values(user?.client?.alerts ?? {});

    const [showPanel, setShowPanel] = useState<boolean>(() => {
        if (typeof window !== 'undefined') {
            // Recuperamos el valor desde localStorage y lo convertimos a booleano
            const panel = localStorage?.getItem('showPanel');
            return panel ? JSON.parse(panel) : true;
        }
    });

    const { fetching } = useRecommendedProducts();

    const changeWidth = () => {
        const showBoth = visibility && showPanel;

        if (width < 1500) {
            if (showBoth) {
                return '437px calc(100% - 774px) 415px';
            } else {
                if (visibility) {
                    return '49px calc(100% - 375px) 415px';
                }
                if (showPanel) {
                    return '437px calc(100% - 400px)';
                }
                return '49px calc(100% - 0.1vw)';
            }
        }

        if (width >= 1500 && width < 1720) {
            if (showBoth) {
                return '437px calc(100% - 814px) 415px';
            } else {
                if (visibility) {
                    return '49px calc(100% - 415px) 415px';
                }
                if (showPanel) {
                    return '437px calc(100% - 379.9px)';
                }
                return '49px calc(100% + 20px)';
            }
        }

        if (width >= 1700 && width <= 1800) {
            if (showBoth) {
                return '437px calc(100% - 820px) 415px';
            } else {
                if (visibility) {
                    return '49px calc(100% - 420px) 415px';
                }
                if (showPanel) {
                    return '437px calc(100% - 357px)';
                }
                return '49px calc(100% + 40px)';
            }
        }

        if (width > 1800) {
            if (showBoth) {
                return '437px calc(100% - 835px) 415px';
            } else {
                if (visibility) {
                    return '49px calc(100% - 435px) 415px';
                }
                if (showPanel) {
                    return '437px calc(100% - 357px)';
                }
                return '49px 91.4vw';
            }
        }
    };

    useEffect(() => {
        if (EX6999) {
            localStorage?.removeItem('genericSwitch');
        }

        if (EX6914) {
            setIdsDeleted([]);
            localStorage?.removeItem('requestedVendors');
        }

        localStorage?.removeItem('redirectTo');

        if (window != undefined) {
            setWidth(window.innerWidth);
        }
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Guardamos el estado de `showPanel` como booleano en localStorage
        localStorage?.setItem('showPanel', JSON.stringify(showPanel));
    }, [showPanel]);

    return (
        <>
            <DrugManufacturerWidgetProvider>
                {counterStep === '#step1' && <div className={styles['backdrop-step1']}></div>}
                {counterStep === '#step2' && <div className={styles['backdrop-step2']}></div>}
                {/* modal empty cart */}
                <ModalConfirmation
                    open={openModalDelete}
                    handleClose={() => setOpenModalDelete(!openModalDelete)}
                    handleConfirm={() => emptyCart(query?.posId)}
                    backdrop={true}
                    title={t('shoppingCart.shoppingCart_Seventeen')}
                    body={t('shoppingCart.shoppingCart_Eighteen')}
                />

                <div
                    className={styles['grid-container']}
                    style={{ gridTemplateColumns: changeWidth(), gap: !validateERP ? 8 : 20 }}
                >
                    {/* List DMs */}
                    <div
                        id="step1"
                        className={styles['left-column']}
                        style={{
                            width: showPanel ? 437 : 49,
                            paddingBottom: footerExpanded ? 64 : 16,
                            height: `calc(100vh - ${showFooterContext ? (alerts?.length > 0 ? 172 : 102) : 56}px)`,
                        }}
                    >
                        <RsuiteTooltip
                            text={
                                showPanel
                                    ? t('home.tabs.collapsedDrugmanufacturer')
                                    : t('home.tabs.expandedDrugmanufacturer')
                            }
                            placement={'right'}
                            trigger={'hover'}
                            minWidth="193px"
                            width="250"
                            padding={'4px 12px'}
                            fontSize={14}
                            textAlign={'left'}
                        >
                            <button
                                className={`${styles['arrow']} button__${showPanel ? 'close' : 'open'}-panel`}
                                onClick={() => {
                                    localStorage?.setItem('showPanel', JSON.stringify(!showPanel)),
                                        setShowPanel(!showPanel);
                                }}
                            >
                                {showPanel ? (
                                    <img src={'/assets/images/chevron_left.svg'} />
                                ) : (
                                    <img src={'/assets/images/chevron_right.svg'} />
                                )}
                            </button>
                        </RsuiteTooltip>
                        {showPanel && <DrugManufacturerWidget />}
                    </div>

                    {/* Recommended */}
                    <div
                        className={styles['middle-column']}
                        style={{
                            paddingBottom: footerExpanded
                                ? alerts?.length > 0
                                    ? 140
                                    : 70
                                : alerts?.length > 0
                                ? 96
                                : visibility
                                ? 66
                                : 25,
                            position: !validateERP ? 'relative' : 'inherit',
                            width: !validateERP ? '100%' : '',
                            height: !validateERP ? '100%' : '',
                        }}
                    >
                        {!validateERP ? (
                            <DragAndDropUploaderModule showPanel={showPanel} />
                        ) : (
                            <>
                                <div className={styles['row-1']}>
                                    <CarouselModule
                                        content={firstCarouselItems}
                                        headerText={t('home.sliderTitle')}
                                        EmptyComponent={RecommendedCardEmpty}
                                        ItemComponent={RecommendedItem}
                                        fetching={fetching}
                                        showPopoverPortalDiv={true}
                                        RecommendedCardNotResults={RecommendedCardNotResults}
                                        text={
                                            !fetching
                                                ? firstCarouselItems?.length === 0 && secondCarouselItems?.length === 0
                                                    ? `${t('home.emptyCarousel.all.text')}`
                                                    : `${t('home.emptyCarousel.single.text')}`
                                                : ''
                                        }
                                        subText={!fetching ? `${t('home.emptyCarousel.subtext')}` : ''}
                                        recommendedNotResults={
                                            !fetching
                                                ? firstCarouselItems?.length === 0 && secondCarouselItems?.length === 0
                                                : false
                                        }
                                    />
                                </div>
                                <div
                                    className={styles['row-2']}
                                    style={{
                                        display: !fetching ? (firstCarouselItems?.length === 0 ? 'none' : '') : '',
                                    }}
                                >
                                    <CarouselModule
                                        content={secondCarouselItems}
                                        headerText={t('home.sliderTitle')}
                                        EmptyComponent={RecommendedCardEmpty}
                                        ItemComponent={RecommendedItem}
                                        fetching={fetching}
                                        showPopoverPortalDiv={true}
                                        RecommendedCardNotResults={RecommendedCardNotResults}
                                        text={!fetching ? `${t('home.emptyCarousel.single.text')}` : ''}
                                        subText={!fetching ? `${t('home.emptyCarousel.subtext')}` : ''}
                                        recommendedNotResults={false}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div
                        className={styles['right-column']}
                        style={{
                            zIndex: isOpen ? 1100 : 0,
                        }}
                    >
                        <Cart />
                    </div>
                </div>
            </DrugManufacturerWidgetProvider>
        </>
    );
};

export default HomeModule;
