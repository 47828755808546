import { useEffect, useRef, useState } from 'react';
// components
import { DetailProduct, FakeLoading } from 'commons/components/DetailProduct';
// STYLES
import styles from '../../RecommendedProducts.module.css';
import style from './RecommendedCardBodyDescription.module.css';
// FF
import { EX4698 } from 'config/flags';
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';

export const RecommendedCardBodyDescription = ({ recommended, popoverPortalDiv, visibleIds }) => {
    // ref
    const popoverRef = useRef(null);
    const containerRef = useRef(null);
    // state
    const [imageLoad, setImageLoad] = useState<boolean>(false);

    // init
    useEffect(() => {
        if (imageLoad) setImageLoad(false);
    }, [recommended?.product?.ean]);

    let openPopover;

    switch (true) {
        case recommended?.product?.drug:
            openPopover = false;
            break;
        case !recommended?.product?.drug:
            openPopover = true;
            break;
        default:
            break;
    }

    const popoverPosition = () => {
        if (visibleIds[0] === recommended?.id) {
            return 'topStart';
        } else if (visibleIds[visibleIds.length - 1] === recommended?.id) {
            return 'leftStart';
        } else if (visibleIds[visibleIds.length - 2] === recommended?.id) {
            return 'topStart';
        } else {
            return 'topStart';
        }
    };
    return (
        <div className={style['container']}>
            {!!EX4698 ? (
                <div ref={containerRef} style={{ position: 'relative' }}>
                    <DetailProduct
                        activePrinciple={recommended?.product?.drug}
                        category={recommended?.product?.category}
                        labBrand={recommended?.product?.laboratory}
                        medicine={`${recommended?.product?.name || ''} ${recommended?.product?.presentation || ''}`}
                        imageSrc={`${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_ONE}/${recommended?.product?.ean}.png`}
                        onLoad={(e: any) => setImageLoad(true)}
                        fakeLoading={<FakeLoading imageLoad={imageLoad} />}
                        innerRef={popoverRef}
                        onClick={() => popoverRef?.current?.close()}
                        imageLoad={imageLoad}
                        disabled={openPopover}
                        placement={popoverPosition()}
                        container={popoverPortalDiv}
                        onClose={() => {}}
                        onOpen={() => {}}
                        laboratory={capitalizeEveryFirstLetter(recommended?.product?.laboratory)}
                        description={`${capitalizeEveryFirstLetter(
                            recommended?.product?.name,
                        )} ${capitalizeEveryFirstLetter(recommended?.product?.presentation)}`}
                        productName={capitalizeEveryFirstLetter(recommended?.product?.name)}
                        presentation={capitalizeEveryFirstLetter(recommended?.product?.presentation)}
                    >
                        <div>
                            <span className={`${styles.name} ${!openPopover && styles.clickable}`}>
                                {capitalizeEveryFirstLetter(
                                    `${recommended?.product?.name} ${recommended?.product?.presentation || ''}`,
                                )}
                                {/* {recommended?.product?.name} {recommended?.product?.presentation} */}
                            </span>
                            {
                                recommended?.product?.drug === null || (

                                    <RsuiteTooltip
                                        placement="bottomStart"
                                        text={capitalizeEveryFirstLetter(`${recommended?.product?.drug}`)}
                                        trigger="hover"
                                        width="200px"
                                        minWidth="200px"
                                        marginTop={4}
                                        disabled={recommended?.product?.drug?.length < 35}
                                    >
                                        <span className={`${styles.drug}`}>
                                            {capitalizeEveryFirstLetter(`${recommended?.product?.drug || ''}`)}
                                        </span>
                                    </RsuiteTooltip>
                                )
                            }
                        </div>
                    </DetailProduct>
                </div>
            ) : (
                <span className={`${styles.name} ${!openPopover && styles.clickable}`}>
                    {recommended?.product?.name} {recommended?.product?.presentation}
                </span>
            )}
        </div>
    );
};
