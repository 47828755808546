// COMPONENT
import { Placeholder } from 'rsuite';
// STYLES
import styles from './RecommendedCardEmpty.module.css';

export const RecommendedCardEmpty = () => {
    const fake = Array.from({ length: 1 }, (_, idx) => {});
    return (
        <>
            {fake.map((f, index) => {
                return (
                    <div className={styles['container']} key={`recommendedEmptyV2_${index}`}>
                        <div className={styles['parent']} style={{ gap: 4 }}>
                            {/* chip top */}
                            <Placeholder.Paragraph
                                className={styles['child-offer-label']}
                                active
                                rows={1}
                                rowHeight={22}
                                style={{
                                    minHeight: 22,
                                }}
                            />
                            {/* img */}
                            <div className={styles['child-img']}>
                                <Placeholder.Paragraph
                                    graph="image"
                                    active
                                    rows={0}
                                    style={{
                                        height: 130,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                />
                            </div>
                            {/* description text */}
                            <div
                                style={{
                                    minHeight: 41,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}
                            >
                                <Placeholder.Paragraph
                                    className={styles['child-two']}
                                    active
                                    rows={2}
                                    rowHeight={20}
                                    style={{
                                        minHeight: 20,
                                    }}
                                />
                            </div>
                            {/* price */}
                            <div
                                style={{
                                    minHeight: 44,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}
                            >
                                <Placeholder.Paragraph
                                    className={styles['child']}
                                    active
                                    rows={1}
                                    rowHeight={20}
                                    style={{
                                        minHeight: 20,
                                    }}
                                />
                            </div>
                            {/* button */}
                            <Placeholder.Graph
                                className={styles['button']}
                                active
                                style={{
                                    minHeight: 48,
                                }}
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default RecommendedCardEmpty;
